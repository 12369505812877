// extracted by mini-css-extract-plugin
export var actash2 = "hero-module--actash2--7b205";
export var btn = "hero-module--btn--1203d";
export var headerWrap = "hero-module--headerWrap--97022";
export var heroImage = "hero-module--heroImage--b4bfb";
export var heroNumbers = "hero-module--heroNumbers--b68af";
export var home = "hero-module--home--91ddb";
export var mouse = "hero-module--mouse--725c4";
export var next = "hero-module--next--964a2";
export var pageChild = "hero-module--pageChild--efeab";
export var pageHeader = "hero-module--pageHeader--6be83";
export var pageHeaderAlt = "hero-module--pageHeader--alt--2f253";
export var pageHeaderTitle = "hero-module--pageHeaderTitle--77193";
export var prev = "hero-module--prev--7b457";
export var prompt = "hero-module--prompt--66c14";
export var scrollAnimationBig = "hero-module--scrollAnimationBig--0f4b6";
export var scrollAnimationSmall = "hero-module--scrollAnimationSmall--6ca21";
export var single = "hero-module--single--38b76";
export var slide = "hero-module--slide--d862d";
export var slide2 = "hero-module--slide2--f28bc";
export var slide3 = "hero-module--slide3--1db90";
export var slideDesc = "hero-module--slideDesc--b596d";
export var slideNum = "hero-module--slideNum--87418";
export var slideOne = "hero-module--slideOne--8f90a";
export var slideThree = "hero-module--slideThree--5c5a0";
export var slideTitle = "hero-module--slideTitle--b9ee0";
export var slideTwo = "hero-module--slideTwo--8437f";
export var slides = "hero-module--slides--44b26";
export var slidesWrap = "hero-module--slidesWrap--3d072";
export var svgScroll = "hero-module--svgScroll--9d32e";